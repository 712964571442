.table-inner {
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    display: inline-block;
}

@media (min-width: 992px) {
    .middle-container {
        max-width: 960px;
    }
}

@media (min-width: 768px) {
    .date-sytle {
        padding: 0.45rem 0.9rem;
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--ct-input-color);
        background-color: var(--ct-input-bg);
        background-clip: padding-box;
        border: 1px solid var(--ct-input-border-color);
        -webkit-appearance: none;
        appearance: none;
        border-radius: 0.2rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .text_right {
        text-align: right;
    }

    .text_center_large {
        text-align: center !important;
    }

    .middle-container {
        max-width: 540px;
    }
}

@media (min-width: 576px) {
    .middle-container {
        max-width: 720px;
    }
}

@media (min-width: 575px) {
    .text_center {
        text-align: center !important;
    }
}

@media (min-width: 575px) {
    .block_575_min {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .date-sytle {
        display: block;
        width: 100%;
        padding: .45rem .9rem;
        font-size: .9rem;
        font-weight: 400;
        line-height: 1.5;
        color: var(--ct-input-color);
        background-color: var(--ct-input-bg);
        background-clip: padding-box;
        border: 1px solid var(--ct-input-border-color);
        -webkit-appearance: none;
        appearance: none;
        border-radius: .2rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .m_t_s {
        margin-top: .375rem !important;
    }

    .text_center_s {
        text-align: center !important;
    }
}



.center_vertical {
    vertical-align: middle !important;
}

.table-x-scroll {
    overflow-x: scroll;
}

.line_inner {
    width: auto !important;
    display: inline-block !important;
}

#table_pageinage {
    width: auto;
    display: inline-block;
}

#table_unit {
    width: auto;
    display: inline-block;
}

#privilege_unit {
    width: auto;
    display: inline-block;
}

#table_maxOnePage {
    width: auto;
    display: inline-block;
}

#dropfify_custom_message {
    font-size: 25px;
}

.text_right_all {
    text-align: right !important;
}


.no-show {
    display: none !important;
}

.width-nine {
    width: 96.5% !important;
}

.layout_center {
    text-align: center !important;
}

.scroll-hidden {
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
}

.task-participates-owner-name {
    border: 2px solid red;
    color: cornflowerblue;
    border-radius: 50%;
    height: 3em;
    width: 3em;
    text-align: center;
    vertical-align: middle;
    line-height: 2.5em;
    display: inline-block;
    padding: 0px;
    margin-right: 0.5em;
    overflow: hidden;
}

.task-participates-name {
    border: 2px solid rgba(255, 255, 255, .15);
    color: cornflowerblue;
    border-radius: 50%;
    height: 3em;
    width: 3em;
    text-align: center;
    vertical-align: middle;
    line-height: 2.5em;
    display: inline-block;
    padding: 0px;
    margin-right: 0.5em;
    overflow: hidden;
}

.multiLinePara {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    /* 这个值取决于你的行高 */
    max-height: 3em;
    /* 2行行高的和 */
}

.file-content {
    border: 2px dashed var(--ct-input-border-color);
    background: var(--ct-input-bg);
    border-radius: 6px;
    padding: 5px;
    margin: 5px;
    overflow: hidden;
}

.line_inner_block {
    display: inline-block !important;
}

.table-form-control {
    display: block;
    width: 100%;
    /* padding: .45rem .9rem; */
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--ct-input-color);
    background-color: var(--ct-input-bg);
    background-clip: padding-box;
    border: 1px solid var(--ct-input-border-color);
    -webkit-appearance: none;
    appearance: none;
    border-radius: .2rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.task-list-single {
    border: 1px solid #3a4250;
}

.horizontal-center {
    width: auto !important;
    /* 设置宽度 */
    margin: 0 auto in !important;
    /* 上下间距为0，左右自动（实现水平居中） */
}

.modal-picture-dialog {
    max-width: 0 !important;
    margin: 25% !important;
}

/* 模态框的样式 */
.modal-all {
    display: none;
    /* 默认隐藏 */
    position: fixed;
    /* 固定位置 */
    z-index: 1;
    /* 位于顶层 */
    left: 0;
    top: 0;
    width: 100%;
    /* 全屏宽度 */
    height: 100%;
    /* 全屏高度 */
    overflow: auto;
    /* 超出内容可滚动 */
    background-color: rgb(0, 0, 0);
    /* 背景颜色带些透明度 */
    background-color: rgba(0, 0, 0, 0.4);
    /* 透明度 */
}

/* 模态框内容的样式 */
.modal-all-content {
    position: fixed;
    margin: 15% auto;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
}