// 
// chat.scss
//



.chat-list {
    li {
        a {
            display: block;
            color: #{map-get($grays, "600")};
            position: relative;
            padding: 12px 14px;
            font-size: 13px;

            .chat-user-img {

                img,
                .avatar-title {
                    padding: 2px;
                    border: 2px solid #{map-get($grays, "100")};
                }

                &.active {

                    img,
                    .avatar-title {
                        border-color: $success !important;
                    }
                }
            }
        }

        &.active {
            a {
                background-color: #{map-get($grays, "100")};
            }
        }

        &.unread {
            a {
                &::after {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $primary;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}


.conversation-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li {
        margin-bottom: 24px;

        .chat-day-title {
            position: relative;
            text-align: center;
            margin-bottom: 24px;

            .title {
                background-color: $card-bg;
                position: relative;
                z-index: 1;
                padding: 6px 24px;
            }

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                left: 0;
                right: 0;
                background-color: #{map-get($grays, "100")};
                top: 10px;
            }
        }

        .message-list {
            &::after {
                display: block;
                clear: both;
                content: "";

            }
        }
    }

    .chat-avatar {
        float: left;
        text-align: center;
        width: 48px;

        img {
            border-radius: 50%;
            width: 100%;
        }

    }

    .ctext-wrap {
        background: $primary;
        border-radius: 0px 6px 6px 6px;
        display: inline-block;
        padding: 12px;
        position: relative;
        color: $white;

        &:after {
            content: " ";
            position: absolute;
            right: 100%;
            top: 0;
            border: solid transparent;
            border-right-color: $primary;
            border-top-color: $primary;
            border-width: 5px;
        }

        .user-name {
            display: block;
            font-size: 12px;
            font-weight: $font-weight-semibold;
            position: relative;
        }

        p {
            margin: 0;
            padding-top: 4px;
        }
    }

    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;

        .time {
            display: block;
            font-size: 12px;
            margin-top: 6px;
        }
    }

    .odd {
        .chat-avatar {
            float: right;
        }

        .conversation-text {
            float: right;
            margin-left: 0px;
            margin-right: 12px;
            text-align: right;
        }

        .ctext-wrap {
            background-color: #{map-get($grays, "100")};
            color: $body-color;
            border-radius: 6px 0px 6px 6px;

            &:after {
                left: 100%;
                right: auto;
                border-right-color: transparent;
                border-left-color: #{map-get($grays, "100")};
                border-top-color: #{map-get($grays, "100")};
            }
        }
    }
}

.chat-send {
    @media (max-width: 575.98px) {
        min-width: auto;
    }
}


// dark mode
body[data-layout-color="dark"] {
    .chat-list {
        li {
            a {
                display: block;
                color: #{map-get($dark-grays, "600")};

                .chat-user-img {

                    img,
                    .avatar-title {
                        border: 2px solid #{map-get($dark-grays, "300")};
                    }
                }
            }

            &.active {
                a {
                    background-color: #{map-get($dark-grays, "300")};
                }
            }
        }
    }

    .conversation-list {
        li {
            .chat-day-title {
                &:before {
                    background-color: #{map-get($dark-grays, "300")};
                }
            }
        }

        .odd {
            .ctext-wrap {
                color: var(--#{$variable-prefix}body-color);
                background-color: #{map-get($dark-grays, "300")};

                &:after {
                    border-left-color: #{map-get($dark-grays, "300")};
                    border-top-color: #{map-get($dark-grays, "300")};
                }
            }
        }
    }
}