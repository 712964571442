// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background-image: $auth-img-bg;
    background-size: cover;
    background-position: center;
}

// Logout page
.logout-icon {
    width: 140px;
}

.auth-logo {
    .logo-light {
        display: $logo-auth-light-display !important;
    }

    .logo-dark {
        display: $logo-auth-dark-display !important;

    }
}

.anth-max{
    max-width: 456px;
}

.captcha-group {
    display: none;
}

.captcha-input {
    width: 61%;
    display: inline-block;
}

.captcha-imgdiv {
    width: 37%;
    display: inline-block;
}

// dark mode
body[data-layout-color="dark"] {
    .auth-logo {
        .logo-light {
            display: $logo-auth-dark-display !important;
        }

        .logo-dark {
            display: $logo-auth-light-display !important;

        }
    }
}